import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from '@material-ui/core';

import { formatStripePrice, sumUpAllStripePrices } from '../../utils/format-stripe-values';

import { IconButton } from '../button-icons';

export const ProductTable = ({ handleDeleteFromCart, cart }) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer component="div">
      <Table aria-label="shopping cart content">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {!isSmallScreen && <TableCell>Beschreibung</TableCell>}
            {!isSmallScreen && <TableCell>Einzelpreis</TableCell>}
            <TableCell>Menge</TableCell>
            <TableCell>Preis gesamt</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((c) => (
            <TableRow key={c.id}>
              <TableCell component="th" scope="row">
                {c.name}
              </TableCell>
              {!isSmallScreen && <TableCell>{c.description}</TableCell>}
              {!isSmallScreen && <TableCell>{formatStripePrice(c.unit_amount, c.currency)}</TableCell>}
              <TableCell>{c.quantity}</TableCell>
              <TableCell>{formatStripePrice(c.unit_amount * c.quantity, c.currency)}</TableCell>
              <TableCell>
                <IconButton
                  iconName="mdiDeleteOutline"
                  iconClass="iconDanger"
                  buttonProps={{ size: 'small', onClick: () => handleDeleteFromCart(c) }}
                  iconProps={{ size: 0.9 }}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row" />
            {!isSmallScreen && <TableCell />}
            {!isSmallScreen && <TableCell />}
            <TableCell />
            <TableCell>{sumUpAllStripePrices(cart, cart[0].currency)}</TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProductTable.propTypes = { cart: PropTypes.array.isRequired, handleDeleteFromCart: PropTypes.func.isRequired };

ProductTable.defaultProps = {};
