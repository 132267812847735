import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.textColors.primary },
  contrastText: { color: theme.palette.primary.contrastText },
  secondary: { color: theme.palette.textColors.secondary },
  accent: { color: theme.palette.textColors.accent }
}));

function StyledTypography({ children, colorClass, ...rest }) {
  const classes = useStyles();
  return (
    <Typography className={classes[colorClass]} {...rest}>
      {children}
    </Typography>
  );
}
StyledTypography.propTypes = { children: PropTypes.node, colorClass: PropTypes.string };
StyledTypography.defaultProps = { children: null, colorClass: null };

export default StyledTypography;
