import { umlaute } from './umlautsMap';

function restoreUmlauts(str) {
  let restoredStr = str;
  for (let u = 0; u < umlaute.length; u++) {
    restoredStr = restoredStr.replace(umlaute[u][0], umlaute[u][1]);
  }
  return restoredStr;
}

function capitalizeFirstLetter([first, ...rest], locale) {
  return [first.toLocaleUpperCase(locale), ...rest].join('');
}

function normalizePathToPagename(str) {
  const pagePathSplitted = str.split('/').filter((f) => f !== '');
  const preName = pagePathSplitted[pagePathSplitted.length - 1];
  const normalizedName = restoreUmlauts(preName).replace(/-/g, ' ');

  return normalizedName;
}

function capitalizeWordsInSentence(sentence) {
  return sentence.split(' ').map(w => capitalizeFirstLetter(w)).join(' ');
}

export const Helpers = Object.freeze({
  capitalizeFirstLetter,
  normalizePathToPagename,
  restoreUmlauts,
  capitalizeWordsInSentence
});
