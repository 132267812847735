const _roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2');

export const formatStripePrice = (amount, currency) => {
  let price = _roundToTwo(amount / 100);
  let numberFormat = new Intl.NumberFormat(['de-DE'], {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  });
  return numberFormat.format(price);
};

export const formatStripePriceWithDiscount = (productCount, amount, currency, multiplyQuant = false, noFormatPrice = false) => {
  // to anyone passing by, this is for client only. The Server rechecks the values for FRAUD PROTECTION.
  const discountValue = 0.05;
  const discountFactor = ~~(productCount / 50) < 6 ? ~~(productCount / 50) + 1 : 7;
  const discount = _roundToTwo(discountValue * discountFactor);
  let price;
  let numberFormat;

  if (productCount < 10) {
    price = _roundToTwo(amount / 100);
  } else {
    const calculatedDiscount = _roundToTwo(amount * discount);
    price = _roundToTwo((amount - calculatedDiscount) / 100);
  }

  if (multiplyQuant) {
    price *= productCount;
  }

  if (noFormatPrice) {
    return price;
  }

  numberFormat = new Intl.NumberFormat(['de-DE'], {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  });
  return numberFormat.format(price);
};

export const sumUpAllStripePrices = (prices, currency) => {
  const summedPrice = prices.reduce((acc, curr) => acc + (curr.quantity * curr.unit_amount),  0);
  return formatStripePrice(summedPrice, currency)
};

export const formatStripeBillingScheme = (scheme) => {
  switch (scheme.toLowerCase()) {
  case 'per_unit':
    return 'Stk.';
  default:
    return 'Stk.';
  }
};
