import React from 'react';
import { Link } from 'gatsby';
import { Box, Grid, Typography, Link as MuiLink } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: '5vh',
    paddingBottom: '5vh',
    color: fade(theme.palette.primary.contrastText, 0.35),
    backgroundColor: theme.palette.primary.dark
  },
  footerContact: {
    paddingTop: '5vh',
    paddingBottom: '5vh',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.dark
  },
  footerLinks: {
    color: fade(theme.palette.primary.contrastText, 0.35),
    textDecoration: 'underline',
    marginRight: 15,
    '& :active': {
      color: fade(theme.palette.primary.contrastText, 0.35),
      textDecoration: 'underline'
    },
    '& :hover': {
      color: fade(theme.palette.primary.contrastText, 0.35),
      textDecoration: 'underline'
    },
    '& :visited': {
      color: fade(theme.palette.primary.contrastText, 0.35),
      textDecoration: 'underline'
    }
  }
  // footerContainer: { minHeight: '50%', backgroundColor: darken(theme.palette.primary.contrastText, 0.3) }
}));



function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <Box>
      <Grid container className={classes.footerContact} direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="overline" align="center" component="div" color="inherit">
            Wir sind von Montag – Freitag zwischen 7:00 Uhr – 16:00 Uhr für Sie da.
          </Typography>
          <Typography variant="subtitle2" align="center" component="div" color="inherit">
            STARK Dynamics GmbH
          </Typography>
          <Typography variant="body1" align="center" component="div" color="inherit">
            Uracher Str. 37-39
          </Typography>
          <Typography variant="body1" align="center" component="div" color="inherit">
            D-72581 Dettingen an der Erms
          </Typography>
          <Typography variant="body1" align="center" component="div" color="inherit">
            Tel.: 0160 98449493
          </Typography>
          <Typography variant="body1" align="center" component="div" color="inherit">
          E-Mail: {' '}
          <MuiLink  href="mailto:info@stark-dynamics.com" color="inherit">info@stark-dynamics.com</MuiLink>
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.footer} direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="overline" align="center" component="div" color="inherit">
            <Link className={classes.footerLinks} to="/impressum">Impressum</Link>
            <Link className={classes.footerLinks} to="/agb">AGB</Link>
            <Link className={classes.footerLinks} to="/datenschutz">Datenschutz</Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" align="center" component="div" color="inherit">
            {' '}
            &copy; STARK - Dynamics GmbH
            {` ${currentYear}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>

  );
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
