/*******************************************
 * All Actions related to drawer pages.    *
 *******************************************/

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export const toggleDrawer = (drawerOpen = false) => ({
  type: TOGGLE_DRAWER,
  isOpen: !drawerOpen
});
