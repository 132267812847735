import { mdiCheck, mdiCheckCircle, mdiCheckDecagram, mdiCircleMedium, mdiShieldCheck, mdiSale, mdiLinkVariant } from '@mdi/js';

export const iconMap = {
  mdiCheckCircle,
  mdiCheck,
  mdiCheckDecagram,
  mdiCircleMedium,
  mdiShieldCheck,
  mdiSale,
  mdiLinkVariant
};
