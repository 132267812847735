/***************************************
 * Theme Design for Dream-bit-website. *
 ***************************************/

import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
// import { Variables } from '../styles/variables';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#fafafa'
    },
    secondary: {
      light: '#67daff',
      main: '#03a9f4',
      dark: '#007ac1',
      contrastText: '#fafafa'
    },
    textColors: {
      primary: fade('#000000', 0.87),
      secondary: fade('#fafafa', 1),
      accent: fade('#03a9f4', 1)
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h1: { fontFamily: 'Goblin One, cursive' },
    h2: { fontFamily: 'Goblin One, cursive' },
    h3: { fontFamily: 'Goblin One, cursive' },
    h4: { fontFamily: 'Goblin One, cursive' },
    h5: { fontFamily: 'Goblin One, cursive' },
    h6: { fontFamily: 'Goblin One, cursive' }
  }
});
