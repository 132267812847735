/*******************************************
 * All Actions related to appbar.          *
 *******************************************/

export const TRIGGER_HIDE_APPBAR = 'TRIGGER_HIDE_APPBAR';

export const triggerHideAppBar = (trigger) => ({
  type: TRIGGER_HIDE_APPBAR,
  hide: trigger || true
});
