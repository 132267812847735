import { SHOPPING_CART_TOGGLE, SHOPPING_CART_ADD_OR_UPDATE_PRODUCT, SHOPPING_CART_DELETE_PRODUCT, SHOPPING_CART_DELETE_ALL_PRODUCTS } from '../constants';

const initialState = {
  open: false,
  cart: []
};

export const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
  case SHOPPING_CART_ADD_OR_UPDATE_PRODUCT: {
    if (state.cart.find((f) => f.id === action.payload.id)) {
      return {
        ...state,
        cart: state.cart.map((s) => {
          if (s.id === action.payload.id) {
            const quantity = s.quantity + action.payload.quantity > 10000 ? 10000 : s.quantity + action.payload.quantity;
            return { ...s, quantity };
          }
          return s;
        })
      };
    }

    return { ...state, cart: [...state.cart, action.payload] };
  }
  case SHOPPING_CART_DELETE_PRODUCT: {
    return { ...state, cart: state.cart.filter((f) => f.id !== action.payload) };
  }
  case SHOPPING_CART_TOGGLE: {
    return { ...state, open: action.payload };
  }
  case SHOPPING_CART_DELETE_ALL_PRODUCTS: {
    return initialState;
  }
  default: {
    return state;
  }
  }
};
