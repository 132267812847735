import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({ roundedCorners: { borderRadius: 12 } });

function StyledButton({ children, styling, ...rest }) {
  const classes = useStyles();
  return (
    <Button className={`${classes[styling]}`} {...rest}>
      {children}
    </Button>
  );
}

StyledButton.propTypes = {
  children: PropTypes.node,
  styling: PropTypes.oneOf(['default', 'roundedCorners'])
};

StyledButton.defaultProps = { children: null, styling: 'default' };

export default StyledButton;
