import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { Avatar, List, ListItem, ListItemText, ListItemAvatar, ListItemIcon, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import { iconMap } from '../../helpers/icon-map';
import { useTransformMarkdownProps } from '../../hooks/useTransformMarkdownProps';
import { useStaticQueries } from '../../hooks/static-queries';
import { Paragraph } from '../typography';
import { Helpers } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginTop: 25,
    marginBottom: 35
  },
  listElement: {},

  avatarPrimary: { backgroundColor: theme.palette.primary.main },
  avatarSecondary: { backgroundColor: theme.palette.secondary.main },
  avatarContrastText: { backgroundColor: theme.palette.primary.contrastText },

  iconPrimary: { color: theme.palette.primary.main },
  iconSecondary: { color: theme.palette.secondary.main },
  iconContrastText: { color: theme.palette.primary.contrastText }
}));

export const ListContainer = ({ children, ...rest }) => {
  const classes = useStyles();
  const props = useTransformMarkdownProps({ ...rest });
  return (
    <List className={classes.listContainer} {...props}>
      {children}
    </List>
  );
};
ListContainer.propTypes = { children: PropTypes.node, properties: PropTypes.object };
ListContainer.defaultProps = { children: null, properties: {} };

export const ListElement = ({ withIconAvatar, iconName, children, iconClass, avatarClass, ...rest }) => {
  const classes = useStyles();
  const props = useTransformMarkdownProps({ ...rest });

  return (
    <ListItem key={uuidv4()} className={classes.listElement} {...props}>
      {withIconAvatar ? (
        <ListItemAvatar>
          <Avatar className={classes[avatarClass] || 'avatarContrastText'}>
            <Icon className={classes[iconClass] || 'iconPrimary'} path={iconMap[iconName]} size={1} />
          </Avatar>
        </ListItemAvatar>
      ) : (
        <ListItemIcon>
          <Icon className={classes[iconClass] || 'iconPrimary'} path={iconMap[iconName]} size={1} />
        </ListItemIcon>
      )}
      <ListItemText>
        {children}
      </ListItemText>
    </ListItem>
  );
};
ListElement.propTypes = {
  children: PropTypes.node,
  properties: PropTypes.object,
  withIconAvatar: PropTypes.string,
  iconName: PropTypes.string,
  iconClass: PropTypes.oneOf(['iconPrimary', 'iconSecondary', 'iconContrastText']),
  avatarClass: PropTypes.oneOf(['avatarPrimary', 'avatarSecondary', 'avatarContrastText'])
};
ListElement.defaultProps = { iconName: 'mdiCircleMedium', children: null, properties: {}, withIconAvatar: null, iconClass: 'iconPrimary', avatarClass: 'avatarContrastText' };

export const ProductListLinks = () => {
  const classes = useStyles();
  const { allPageTree } = useStaticQueries();
  return (
    <List className={classes.listContainer}>
      {allPageTree.nodes.map((e) => (
        <ListItem key={e.id} className={classes.listElement}>
          <ListItemAvatar>
            <Avatar className={classes.listAvatar}>
              <Icon className={classes.listIconAvatar} path={iconMap.mdiSale} size={1} />
            </Avatar>
          </ListItemAvatar>
          <Paragraph paragraph={false}>
            <Link underline="always" href={e.pathRaw}>
              {Helpers.capitalizeWordsInSentence(Helpers.normalizePathToPagename(e.name))}
            </Link>
          </Paragraph>
        </ListItem>
      ))}
    </List>
  );
};
