import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useTransformMarkdownProps } from '../../hooks/useTransformMarkdownProps';

const useStyles = makeStyles({ paragraphs: {}, headings: {} });

export const Paragraph = ({ children, ...rest }) => {
  const classes = useStyles();
  const props = useTransformMarkdownProps({ ...rest });
  return (
    <Typography className={classes.paragraphs} component="div" variant="body1" paragraph {...props}>
      {children}
    </Typography>
  );
};
Paragraph.propTypes = { children: PropTypes.node };
Paragraph.defaultProps = { children: null };

export const Heading = ({ children, variant, ...rest }) => {
  const classes = useStyles();
  const props = useTransformMarkdownProps({ ...rest });
  return (
    <Typography className={classes.headings} component="div" variant={variant} {...props}>
      {children}
    </Typography>
  );
};
Heading.propTypes = { children: PropTypes.node, variant: PropTypes.string };
Heading.defaultProps = { children: null, variant: 'body1' };
