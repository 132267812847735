import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { IconButton as MuiIconButton, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as MDIIcons from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  root: { '& > *': { margin: theme.spacing(1) } },
  iconPrimary: { color: theme.palette.primary.main },
  iconSecondary: { color: theme.palette.secondary.main },
  iconContrastText: { color: theme.palette.primary.contrastText },
  iconDanger: { color: theme.palette.error.main },
  badge: { '& > span': { border: `2px solid ${theme.palette.primary.dark}` } }
}));

export const IconButton = ({ children, iconName, iconClass, buttonProps, iconProps, badgeProps, withBadge }) => {
  const classes = useStyles();
  return withBadge ? (
    <MuiIconButton className={classes.root} {...buttonProps}>
      <Badge className={classes.badge} overlap="circle" {...badgeProps}>
        <Icon className={classes[iconClass] || 'iconPrimary'} path={MDIIcons[iconName]} {...iconProps} />
        {children}
      </Badge>
    </MuiIconButton>
  ) : (
    <MuiIconButton className={classes.root} {...buttonProps}>
      <Icon className={classes[iconClass] || 'iconPrimary'} path={MDIIcons[iconName]} {...iconProps} />
      {children}
    </MuiIconButton>
  );
};

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  withBadge: PropTypes.bool,
  children: PropTypes.node,
  badgeProps: PropTypes.object,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object,
  iconClass: PropTypes.oneOf(['iconPrimary', 'iconSecondary', 'iconContrastText', 'iconDanger'])
};
IconButton.defaultProps = { iconClass: 'iconPrimary', children: null, buttonProps: {}, badgeProps: {}, iconProps: {}, withBadge: false };
