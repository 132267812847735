import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Box, Typography, Paper, Divider, Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { formatStripePrice } from '../../utils/format-stripe-values';
import { addOrUpdateProductInCart } from '../../redux/shopping-cart/actions';
import { cartSelector } from '../../redux/shopping-cart/selectors';

const _metaDataMap = {
  L_nge: 'Länge',
  H_he: 'Höhe',
  Brettst_rke: 'Brettstärke'
};

function ProductCard({ product }) {
  const { description, name, localFiles, metadata, price } = product;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const productQuantityRef = useRef(null);

  const [inputCorrect, setInput] = useState(true);
  const [quantity, setQuantity] = useState(1);

  const maxQuantity = Number(metadata.Bestandsmenge) > 0 ? Number(metadata.Bestandsmenge) : 0;

  const cart = useSelector(cartSelector, shallowEqual);
  const handleQuantityUpdate = useCallback(() => {
    if (
      isNaN(productQuantityRef.current.value)
      || !Number.isInteger(+productQuantityRef.current.value)
      || +productQuantityRef.current.value <= 0
      || +productQuantityRef.current.value > maxQuantity
    ) {
      setInput(false);
      enqueueSnackbar(`Um mehr als ${maxQuantity} zu bestellen, nehmen Sie bitte mit uns Kontakt auf.`, { variant: 'info' });
      return;
    }
    setInput(true);
    setQuantity(+productQuantityRef.current.value);
  }, [enqueueSnackbar, maxQuantity]);

  const positionInCart = cart.find((f) => f.id === product.id);
  const handleAddToCart = useCallback(() => {
    const quantitySum = positionInCart ? positionInCart.quantity + quantity : quantity;
    if (positionInCart && quantitySum >= maxQuantity) {
      enqueueSnackbar(`Um mehr als ${maxQuantity} zu bestellen, nehmen Sie bitte mit uns Kontakt auf.`, { variant: 'info' });

      const tmpQt = quantitySum <= maxQuantity ? quantity : 0;

      if (quantity - positionInCart.quantity >= 0) {
        enqueueSnackbar(`${tmpQt} x ${product.name}, in den Warenkorb hinzugefügt.`, { variant: 'success' });
      }

      dispatch(addOrUpdateProductInCart({ ...price, ...product, quantity: tmpQt }));
      setQuantity(1);
      productQuantityRef.current.value = 1;
      return;
    }
    if (inputCorrect) {
      dispatch(addOrUpdateProductInCart({ ...price, ...product, quantity }));
      enqueueSnackbar(`${quantity} x ${product.name}, in den Warenkorb hinzugefügt.`, { variant: 'success' });
      setQuantity(1);
      productQuantityRef.current.value = 1;
      return;
    }

    enqueueSnackbar('Es sind Mengen von 1 bis 10.000 möglich.', { variant: 'warning' });
  }, [dispatch, enqueueSnackbar, maxQuantity, inputCorrect, positionInCart, price, product, quantity]);

  return (
    <Box mt={4} mb={4}>
      <Paper elevation={4}>
        <div style={{ paddingBottom: 15, height: 250 }}>
          <Img fluid={localFiles[0].childImageSharp.fluid} style={{ maxHeight: 250 }} />
        </div>
        <Box p={2} style={{ height: 120 }}>
          <Typography variant="body1" gutterBottom>
            <b>{name}</b>
          </Typography>
        </Box>

        <Box p={2}>
          {Object.keys(metadata).map((mt) => (metadata[mt] != null && mt !== 'Artikelnummer' ? (
            <Typography key={`${name}-${mt}-${metadata[mt]}`} variant="body1" gutterBottom>
              {_metaDataMap[mt] != null ? _metaDataMap[mt] : mt.replace('_', ' ')}
              {' : '}
              {metadata[mt]}
            </Typography>
          ) : null))}
        </Box>
        <Box p={2}>
          <Divider />
        </Box>
        <Box p={2}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {formatStripePrice(price.unit_amount, price.currency)}
            {' / STK. zzgl. MwSt.'}
          </Typography>
        </Box>
        <Box p={2}>
          <TextField defaultValue={1} label="Stück" type="number" fullWidth onChange={handleQuantityUpdate} inputProps={{ min: 0, max: 1000, ref: productQuantityRef }} />
        </Box>
        <Box p={2}>
          <Button disabled={!inputCorrect || maxQuantity <= 0} variant="contained" color="secondary" size="small" onClick={handleAddToCart} fullWidth>
            {maxQuantity <= 0 ? `Ausverkauft` : `in den Warenkorb`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

ProductCard.propTypes = { product: PropTypes.object.isRequired };

ProductCard.defaultProps = {};

export default ProductCard;
