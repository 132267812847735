import { TRIGGER_HIDE_APPBAR } from './actions';

/**
 * Depending on which element triggered this reducer,
 * it will change the given state.
 * @param  {Object}  [state={}] previous state.
 * @param  {String} action      defined action from action.
 * @return {Object}             returns new state.
 */
export const appbarReducer = (state = {}, action) => {
  // check which action was triggered.
  switch (action.type) {
  case TRIGGER_HIDE_APPBAR:
    return { ...state, appBarHide: action.isOpen };

  default:
    return state;
  }
};
