import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import { useCookies } from 'react-cookie';
import { Collapse, Paper, Typography, Divider, Button, Grid, FormGroup, FormControlLabel, Switch, Container } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  // paperRoot: { maxHeight: 550 },
  paperHeaderTypography: { color: theme.palette.primary.contrastText },
  contrastPaperButton: { borderColor: theme.palette.primary.contrastText, color: theme.palette.primary.contrastText },
  paperHeader: { backgroundColor: theme.palette.primary.main, padding: 8 },
  paperActionButtons: { paddingTop: 8, paddingBottom: 8 },
  paperContent: { padding: 8 },
  cookieElements: { paddingTop: 8 }
}));

const CustomSnackbar = React.forwardRef(({ id, bannerTitle, messageBody, askCookies }, ref) => {
  const classes = useStyles();
  const cookieKeys = Object.keys(askCookies);

  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [, setCookie, removeCookie] = useCookies([...cookieKeys]);
  const [state, setState] = React.useState(cookieKeys.reduce((acc, curr) => (acc[curr] = true) && acc, {}));
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    // setCookie('stark-dynamics-gmbh-gdpr-google-analytics', true, { path: '/' });
    cookieKeys.forEach((k) => removeCookie(k));
    cookieKeys.forEach((k) => setCookie(k, state[k], { path: '/' }));
    closeSnackbar(id);
    setCookie(id, moment().format('YYYY-MM-DD HH:mm:ss'), { path: '/' });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Container maxWidth="md">
        <Paper elevation={4}>
          <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.paperHeader}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.paperHeaderTypography}>
                {bannerTitle}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.paperActionButtons}>
              <Button onClick={handleExpandClick} variant="outlined" color="secondary" size="small">
                Einstellungen
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.paperActionButtons}>
              <Button onClick={handleDismiss} variant="contained" color="secondary" size="small">
                Akzeptieren
              </Button>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={12}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={classes.paperContent}>
                  <Grid container direction="row">
                    <Grid item xs={12} className={classes.cookieElements}>
                      <Typography variant="body1" color="textSecondary">
                        {messageBody}
                      </Typography>
                      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    </Grid>
                    {Object.keys(askCookies).map((k) => (
                      <Grid item xs={12} key={k}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                          <Grid item xs={6} className={classes.cookieElements}>
                            {askCookies[k]}
                          </Grid>
                          <Grid item xs={6}>
                            <FormGroup row>
                              <FormControlLabel control={<Switch checked={state[k]} onChange={handleChange} name={k} />} label={state[k] ? 'erlauben' : 'verweigern'} />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Collapse>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </SnackbarContent>
  );
});

CustomSnackbar.propTypes = {
  id: PropTypes.string.isRequired,
  bannerTitle: PropTypes.string.isRequired,
  askCookies: PropTypes.object.isRequired,
  messageBody: PropTypes.node
};

CustomSnackbar.defaultProps = { messageBody: null };

function CookieConsent({ askCookies, consentId, bannerTitle, messageBody, location }) {
  const { enqueueSnackbar } = useSnackbar();
  const cookieKeys = Object.keys(askCookies);
  const [cookies, setCookie, removeCookie] = useCookies([consentId, ...cookieKeys]);
  moment.suppressDeprecationWarnings = true;

  useEffect(() => {
    if (!moment(cookies[consentId]).isValid() || moment().diff(cookies[consentId], 'hours') > 672) {
      removeCookie(consentId);
      cookieKeys.forEach((k) => removeCookie(k));
    }

    // This will show the consent snackbar only if the given Consent ID have not been set by the user already.
    if (cookies[consentId] == null) {
      enqueueSnackbar(' ', {
        key: consentId,
        persist: true,
        content: <CustomSnackbar id={consentId} bannerTitle={bannerTitle} messageBody={messageBody} askCookies={askCookies} location={location} />
      });
    }
  }, [askCookies, bannerTitle, consentId, cookieKeys, cookies, enqueueSnackbar, location, messageBody, removeCookie, setCookie]);
  return (
    <Helmet>
      {cookies['stark-dynamics-gmbh-gdpr-google-analytics'] != null && cookies['stark-dynamics-gmbh-gdpr-google-analytics'] === 'true' ? (
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-184744864-2" />
      ) : null}

      {cookies['stark-dynamics-gmbh-gdpr-google-analytics'] != null && cookies['stark-dynamics-gmbh-gdpr-google-analytics'] === 'true' ? (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-184744864-2');`}
        </script>
      ) : null}
    </Helmet>
  );
}

export default CookieConsent;

CookieConsent.propTypes = {
  askCookies: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  consentId: PropTypes.string.isRequired,
  bannerTitle: PropTypes.string.isRequired,
  messageBody: PropTypes.node
};

CookieConsent.defaultProps = { messageBody: null };
