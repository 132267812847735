import { STEPPER_STEP_CHANGE, STEPPER_STEP_RESET } from './stepper-constants';

const initialState = { activeStep: 0 };

/**
 * Depending on which element triggered this reducer,
 * it will change the given state.
 * @param  {Object}  [state={}] previous state.
 * @param  {String} action      defined action from action.
 * @return {Object}             returns new state.
 */
export const stepperReducer = (state = initialState, action) => {
  // check which action was triggered.
  switch (action.type) {
  case STEPPER_STEP_CHANGE: {
    return { ...state, activeStep: action.payload.step, hasError: action.payload.hasError };
  }
  case STEPPER_STEP_RESET: {
    return { ...state, activeStep: action.payload.step, hasError: action.payload.hasError };
  }

  default: {
    return state;
  }
  }
};
