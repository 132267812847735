import React from 'react';
import { Link } from 'gatsby';
import { Divider, Link as MuiLink } from '@material-ui/core';
import { Heading } from '../components/typography';
import { ListContainer, ListElement } from '../components/list';
import { Typography, LinkHeading, LinkElement } from '../components';


export const componentsMap = {
  Typography,
  LinkElement,
  LinkHeading,
  MuiLink,
  Link,
  Divider,
  hr: Divider,
  p: ({ children, ...rest }) => <Typography variant="body1" gutterBottom paragraph {...rest}>{ children }</Typography>,
  h1: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h1' }),
  h2: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h2' }),
  h3: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h3' }),
  h4: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h4' }),
  h5: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h5' }),
  h6: ({ children, ...rest }) => Heading({ children, ...rest, variant: 'h6' }),
  ul: ListContainer,
  ol: ListContainer,
  li: ListElement,
  a: ({ children, ...rest }) => <MuiLink underline="always" {...rest} >{children}</MuiLink>
};
