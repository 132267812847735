import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Grid, Container, Box, Typography, makeStyles } from '@material-ui/core';

import { AlertDeleteItemFromCart } from './alert-delete';
import { ProductTable } from './products-table';

import { CheckoutButton } from '../checkout-button';
import { cartSelector, cartPositionsCount } from '../../redux/shopping-cart/selectors';

import EmptyCartSVG from '../../../static/empty_cart.svg';

const useStyles = makeStyles((theme) => ({
  appBar: { position: 'relative', backgroundColor: theme.palette.primary.dark },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    alignSelf: 'center'
  },
  icon: {
    marginLeft: theme.spacing(2),
    flex: 1,
    justify: 'flex-end',
    alignSelf: 'center'
  }
}));

export const Cart = ({ withCheckoutButton }) => {
  const classes = useStyles();
  const cart = useSelector(cartSelector, shallowEqual);
  const cartLength = useSelector(cartPositionsCount, shallowEqual);
  const [currentProduct, setCurrentProduct] = useState({});
  const [openAlert, setOpenAlert] = useState(false);

  const toogleAlert = () => {
    setOpenAlert(!openAlert);
  };

  const handleDeleteFromCart = (product) => {
    setCurrentProduct(product);
    toogleAlert();
  };

  return (
    <Box>
      {cartLength <= 0 ? (
        <Grid container direction="column" justify="space-evenly" alignItems="center">
          <Grid item xs={8} sm={5} md={3}>
            <img src={EmptyCartSVG} alt="empty cart" style={{ paddingTop: '5vh', paddingBottom: '5vh', width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={11} sm={5} md={5}>
            <Typography variant="h6" className={classes.title}>
              Schade, dein Warenkorb ist leider leer.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="lg">
          <ProductTable cart={cart} handleDeleteFromCart={handleDeleteFromCart} />
          {withCheckoutButton ? (
            <Box p={1}>
              <CheckoutButton />
            </Box>
          ) : null}
        </Container>
      )}
      <AlertDeleteItemFromCart open={openAlert} toggleFunc={toogleAlert} product={currentProduct} />
    </Box>
  );
};

Cart.propTypes = { withCheckoutButton: PropTypes.bool };

Cart.defaultProps = { withCheckoutButton: true };
