import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import RandomShapes from '../../../static/random-shapes.svg';
import CogShapes from '../../../static/floating-cogs.svg';

const useStyles = makeStyles((theme) => ({
  randomShapes: {
    // backgroundImage: `url(${RandomShapes})`,
    background: `linear-gradient(45deg, ${fade(theme.palette.primary.main, 1)} 30%, ${fade(theme.palette.primary.main, 0)}) 100%, url(${RandomShapes})`
  },
  cogShapes: {
    // backgroundImage: `url(${RandomShapes})`,
    background: `linear-gradient(45deg, ${fade(theme.palette.primary.main, 1)} 30%, ${fade(theme.palette.primary.main, 0)}) 100%, url(${CogShapes})`
  },
  marginTop: { marginTop: '20vh' },
  marginBottom: { marginBottom: '20vh' },
  marginBoth: { marginTop: '20vh', marginBottom: '20vh' },
  contrastText: { backgroundColor: theme.palette.primary.contrastText },
  primary: { backgroundColor: theme.palette.primary.main },
  primaryLight: { backgroundColor: theme.palette.primary.light },
  primaryDark: { backgroundColor: theme.palette.primary.dark },
  secondary: { backgroundColor: theme.palette.secondary.main },
  secondaryLight: { backgroundColor: theme.palette.secondary.light },
  secondaryDark: { backgroundColor: theme.palette.secondary.dark }
}));

function StyledBox({ children, bgColorClass, marginClass, bgPattern, ...rest }) {
  const classes = useStyles();
  return (
    <Box className={`${classes[marginClass] || ''} ${classes[bgColorClass] || ''} ${classes[bgPattern] || ''}`.trim()} {...rest}>
      {children}
    </Box>
  );
}
StyledBox.propTypes = {
  children: PropTypes.node,
  bgColorClass: PropTypes.string,
  bgPattern: PropTypes.string,
  marginClass: PropTypes.string
};
StyledBox.defaultProps = { children: null, bgColorClass: null, marginClass: null, bgPattern: null };

export default StyledBox;
