import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  headingLink: {
    textDecoration: 'none',
    paddingBottom: '3px',
    color: theme.palette.primary.contrastText,
    '&:after': {
      display: 'block',
      content: '" "',
      borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
      transform: 'scaleX(0.50)',
      transition: 'transform 250ms ease-in-out'
    },
    '&:hover': { '&:after': { transform: 'scaleX(1)' } }
  },
  primary: {
    textDecoration: 'none',
    paddingBottom: '1px',
    color: theme.palette.textColors.primary,
    boxShadow: `0 1px 0 ${theme.palette.primary.main}`,
    transition: '.4s',
    '&:hover': { boxShadow: `0 0 0 ${theme.palette.primary.main}` }
  },
  contrastText: {
    textDecoration: 'none',
    paddingBottom: '1px',
    color: theme.palette.primary.contrastText,
    boxShadow: `0 1px 0 ${theme.palette.primary.contrastText}`,
    transition: '.4s',
    '&:hover': { boxShadow: `0 0 0 ${theme.palette.primary.contrastText}` }
  },
  secondary: {
    textDecoration: 'none',
    paddingBottom: '1px',
    color: theme.palette.textColors.secondary,
    boxShadow: `0 1px 0 ${theme.palette.textColors.secondary}`,
    transition: '.4s',
    '&:hover': { boxShadow: `0 0 0 ${theme.palette.textColors.secondary}` }
  },
  accent: {
    textDecoration: 'none',
    paddingBottom: '1px',
    color: theme.palette.textColors.accent,
    boxShadow: `0 1px 0 ${theme.palette.textColors.accent}`,
    transition: '.4s',
    '&:hover': { boxShadow: `0 0 0 ${theme.palette.textColors.accent}` }
  }
}));

export const LinkElement = ({ children, colorClass, ...rest }) => {
  const classes = useStyles();
  return (
    <Link className={classes[colorClass]} {...rest}>
      {children}
    </Link>
  );
};
LinkElement.propTypes = { children: PropTypes.node, colorClass: PropTypes.string };
LinkElement.defaultProps = { children: null, colorClass: 'primary' };

export const LinkHeading = ({ children, colorClass, ...rest }) => {
  const classes = useStyles();
  return (
    <Link className={classes[colorClass]} {...rest}>
      {children}
    </Link>
  );
};
LinkHeading.propTypes = { children: PropTypes.node, colorClass: PropTypes.string };
LinkHeading.defaultProps = { children: null, colorClass: 'primary' };
