import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import { Button } from '../../dream-bit/components';

const useStyles = makeStyles({
  buttonLinks: {
    color: 'inherit',
    textDecoration: 'none',
    '& :active': {
      color: 'inherit',
      textDecoration: 'none'
    },
    '& :hover': {
      color: 'inherit',
      textDecoration: 'none'
    },
    '& :visited': {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
  // footerContainer: { minHeight: '50%', backgroundColor: darken(theme.palette.primary.contrastText, 0.3) }
});

function ButtonLink({ children, buttonProps, linkProps, ...rest }) {
  const classes = useStyles();
  return (
    <Link className={classes.buttonLinks} {...linkProps} {...rest}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  );
}

ButtonLink.propTypes = { children: PropTypes.node, buttonProps: PropTypes.object, linkProps: PropTypes.object };
ButtonLink.defaultProps = {
  children: null,
  buttonProps: {},
  linkProps: {}
};

export default ButtonLink;
