import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import * as MDIIcons from '@mdi/js';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // iconsRoot: {
  //   verticalAlign: '-0.2555em'
  // },
  buttonRoot: { '& > *': { margin: theme.spacing(1) } },
  contrast: { color: theme.palette.primary.contrastText },
  primary: { color: theme.palette.primary.main },
  secondary: { color: theme.palette.secondary.main }
}));

export const Icons = ({ color, iconName, ...rest }) => {
  const classes = useStyles();
  const iconClass = classes[color];
  return <Icon className={iconClass || ''} path={MDIIcons[iconName]} {...rest} />;
};

Icons.propTypes = { color: PropTypes.string, iconName: PropTypes.string.isRequired };

Icons.defaultProps = { color: 'contrast' };
