/*********************************
 * Configuraton for Redux Store  *
 * plus some Gimmicks.           *
 *********************************/
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';

import combinedReducers from './combined-reducers';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const configureStore = () => {
  const store = createStore(
    persistedReducer,
    applyMiddleware(
      thunkMiddleware // lets us dispatch() functions
      // loggerMiddleware // neat middleware that logs actions
    )
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
