import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Img from 'gatsby-image';

import Typography from '../typography/styled';
import Box from '../box';
import ButtonLink from '../button-links';

const heroContainerZIndex = 1;

const useStyles = makeStyles((theme) => ({
  imageBackdrop: {
    position: 'absolute',
    zIndex: heroContainerZIndex + 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity'),
    opacity: 0.65,
    backgroundColor: theme.palette.text.primary
  },

  heroContainerContent: {
    position: 'absolute',
    zIndex: heroContainerZIndex + 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    color: theme.palette.primary.contrastText
  }
}));

function HeroTitle({ headImage }) {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
      <Img fixed={headImage.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%" alt="head image" />
      <div className={`${classes.imageBackdrop}`} />
      <div className={classes.heroContainerContent}>
        <Grid container direction="row" justify="flex-start" alignItems="center" style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Container maxWidth="lg">
              <Typography variant="overline" style={{ fontSize: '1.1rem' }}>
                Professionell, freundlich und kooperativ.
              </Typography>
              <Typography variant="h4" gutterBottom>
                STARK Dynamics GmbH
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '1.2rem' }}>
                Um die Anforderungen unserer Kunden zu erfüllen,
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: '1.2rem' }} gutterBottom>
                steht persönliche Kommunikation im Mittelpunkt unserer Unternehmenspolitik.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

HeroTitle.propTypes = { headImage: PropTypes.object.isRequired };

HeroTitle.defaultProps = {};

export default HeroTitle;
