import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { Drawer, Box, List, Divider, ListItem, ListItemText } from '@material-ui/core';

import { Helpers } from '../../helpers';
import { toggleDrawer } from '../../redux/drawer/actions';

const useStyles = makeStyles((theme) => ({
  drawerPaper: { backgroundColor: theme.palette.primary.main },
  listItem: {
    color: theme.palette.primary.contrastText,
    '&:hover': { backgroundColor: fade(theme.palette.primary.light, 0.8) }
  },
  listItemNested: {
    color: theme.palette.primary.contrastText,
    '&:hover': { backgroundColor: fade(theme.palette.primary.light, 0.8) },
    paddingLeft: theme.spacing(4)
  },
  listDivider: { backgroundColor: fade(theme.palette.primary.light, 0.12) },
  listLinks: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '&:visted': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText
    }
  },
  firstElemList: { marginTop: 20 }
}));

function Sidebar() {
  const Pages = useStaticQuery(graphql`
    query {
      allPageTree(
        filter: {
          name: { nin: ["Offline plugin-app-shell-fallback", "404", "404.html", "Dev 404-page", "Homepage", "Agb", "Impressum", "Datenschutz"] }
          isRootPage: { eq: true }
        }
      ) {
        nodes {
          id
          name
          pathRaw
          children {
            id
            ... on PageTree {
              id
              name
              pathRaw
            }
          }
        }
      }
    }
  `);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { drawerIsOpen } = useSelector(({ drawerReducer }) => drawerReducer);
  const { nodes } = Pages.allPageTree;
  return (
    <Drawer classes={{ paper: classes.drawerPaper }} anchor="left" open={drawerIsOpen} onClose={() => dispatch(toggleDrawer(true))}>
      <List component="div" onClick={() => dispatch(toggleDrawer(true))} onKeyDown={() => dispatch(toggleDrawer(true))}>
        <Box className={classes.firstElemList} key="sidebar-homepage">
          <Link className={classes.listLinks} to="/">
            <ListItem button className={classes.listItem} component="div">
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
          <Divider className={classes.listDivider} />
        </Box>
      </List>
      {nodes.map((n) => (
        <Box key={`sidebar-${n.id}`}>
          <Link
            className={classes.listLinks}
            to={`${n.pathRaw.charAt(0) === '/' ? n.pathRaw : '/' + n.pathRaw}`}
            onClick={() => dispatch(toggleDrawer(true))}
            onKeyDown={() => dispatch(toggleDrawer(true))}
          >
            <ListItem button className={classes.listItem} component="div">
              <ListItemText primary={Helpers.capitalizeWordsInSentence(Helpers.normalizePathToPagename(n.pathRaw))} />
            </ListItem>
          </Link>
          <Divider className={classes.listDivider} />
          {n.children.length > 0 ? (
            <List component="div" role="presentation" disablePadding onClick={() => dispatch(toggleDrawer(true))} onKeyDown={() => dispatch(toggleDrawer(true))}>
              {n.children.map((nest) => (
                <Box key={`sidebar-nest-${nest.id}`}>
                  <Link
                    className={classes.listLinks}
                    to={`${nest.pathRaw.charAt(0) === '/' ? nest.pathRaw : '/' + nest.pathRaw}`}
                    onClick={() => dispatch(toggleDrawer(true))}
                    onKeyDown={() => dispatch(toggleDrawer(true))}
                  >
                    <ListItem button className={classes.listItemNested} component="div">
                      <ListItemText primary={Helpers.capitalizeWordsInSentence(Helpers.normalizePathToPagename(nest.pathRaw))} />
                    </ListItem>
                  </Link>
                  <Divider className={classes.listDivider} />
                </Box>
              ))}
            </List>
          ) : null}
        </Box>
      ))}
    </Drawer>
  );
}

export default Sidebar;
