import * as ActionTypes from '../constants';

export const addOrUpdateProductInCart = (product) => ({
  type: ActionTypes.SHOPPING_CART_ADD_OR_UPDATE_PRODUCT,
  payload: product
});

export const deleteProductFromCart = (id) => ({
  type: ActionTypes.SHOPPING_CART_DELETE_PRODUCT,
  payload: id
});

export const deleteAllProductsFromCart = () => ({ type: ActionTypes.SHOPPING_CART_DELETE_ALL_PRODUCTS });

export const toggleShoppingCart = (open) => ({
  type: ActionTypes.SHOPPING_CART_TOGGLE,
  payload: !open
});
