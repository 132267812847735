import { unicodeToChar } from './unicodeToChar';

export const umlautsMap = [
  { code: '\u00C4', value: 'Ä', safe: 'AE' },
  { code: '\u00E4', value: 'ä', safe: 'ae' },
  { code: '\u00D6', value: 'Ö', safe: 'OE' },
  { code: '\u00F6', value: 'ö', safe: 'oe' },
  { code: '\u00DC', value: 'Ü', safe: 'UE' },
  { code: '\u00FC', value: 'ü', safe: 'ue' },
  // { code: '\u00DF', value: 'ß', safe: 'ss' }
];

export const umlaute = umlautsMap.map((u) => [u.safe, unicodeToChar(u.code)]);
