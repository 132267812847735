/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MDXProvider } from '@mdx-js/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { configureStore } from './src/redux/configure-store';
import { apolloClient } from './src/apollo';
import { theme } from './src/theme/mui-theme';
import { componentsMap } from './src/helpers/components-map';

/**
 * Creates Store for Redux Pattern with a persistor.
 * Only use one store for the whole app!
 * defines state values.
 * define store with all reducers needed for the app.
 * so basically every single one under ./reducers/*.
 * @type {Store}
 */
const { store, persistor } = configureStore();

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              preventDuplicate
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              autoHideDuration={3500}
            >
              <MDXProvider components={componentsMap}>{element}</MDXProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </CookiesProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

wrapRootElement.propTypes = { element: PropTypes.node.isRequired };
