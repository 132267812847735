// Import all reducers.
import { combineReducers } from 'redux';

import { drawerReducer } from './drawer/reducer';
import { appbarReducer } from './appbar/reducer';
import { shoppingCartReducer } from './shopping-cart/reducer';
import { stepperReducer } from '../components/stepper/redux/stepper-reducer';

// combine and export them.
export default combineReducers({ drawerReducer, appbarReducer, shoppingCartReducer, stepperReducer });
