import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { deleteProductFromCart } from '../../redux/shopping-cart/actions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const AlertDeleteItemFromCart = ({ product, open, toggleFunc }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteItem = () => {
    dispatch(deleteProductFromCart(product.id));
    enqueueSnackbar(`${product.quantity} x ${product.name}, wurde aus dem Warenkorb entfernt.`, { variant: 'info' });
    toggleFunc();
  };

  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={toggleFunc}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Artikel aus dem Warenkorb entfernen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Möchtest du wirklich ${product.quantity} x ${product.name}
            aus dem Warenkorb entfernen? Falls du nur die Menge ändern möchtest, kannst du auf die Menge seperat klicken.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={toggleFunc} color="primary" variant="contained">
            Nein
          </Button>
          <Button fullWidth onClick={handleDeleteItem} color="primary" variant="outlined">
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

AlertDeleteItemFromCart.propTypes = { product: PropTypes.object.isRequired, open: PropTypes.bool.isRequired, toggleFunc: PropTypes.func.isRequired };

AlertDeleteItemFromCart.defaultProps = {};
