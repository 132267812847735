/**
 * This is used to take boolean props from markdown (as string) and convert them to booleans.
 * @param       {Object} props Properties object from mdxAST.
 * @return      {Object}       Strings are now booleans if they matched.
 */
export const useTransformMarkdownProps = (props) => {
  if (props == null) return {};

  return Object.keys(props).reduce((a, c) => {
    // initially set new prop.
    a[c] = props[c];

    // set Everything you want to be changed in here.
    if (typeof props[c] === 'string' && props[c].toLowerCase() === 'true') a[c] = true;
    if (typeof props[c] === 'string' && props[c].toLowerCase() === 'false') a[c] = false;

    // don't forget to return the whole accumulator.
    return a;
  }, {});
};
