import { useStaticQuery, graphql } from 'gatsby';

export const useStaticQueries = () => ({
  ...useStaticQuery(
    graphql`
      query {
        allPageTree(filter: { id: { regex: "/PageTree-produkt.*/" }, isRootPage: { eq: false } }) {
          nodes {
            id
            isRootPage
            name
            pathRaw
          }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
          id
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 600) {
              src
              height
              width
            }
          }
        }
      }
    `
  )
});
